import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { EmojiEvents, Campaign } from "@mui/icons-material";
import Encuentros from "./Encuentros";
import Resultados from "./Resultados";
import Cuotas from "./Cuotas";
import Factores from "./Factores";
import { CuotasDropdown, FactoresDropdown } from "./DropdowsButtons";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-tratos-${index}`}
      aria-labelledby={`tab-tratos-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-tratos-${index}`,
    "aria-controls": `tabpanel-tratos-${index}`,
  };
}

export default function Auditorias() {
  const [value, setValue] = React.useState(0);
  const [typeFactor, setTypeFactor] = React.useState(0);
  const [typeCuota, setTypeCuota] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        //border: "1px solid red",
        height: { xs: `calc(100vh - 120px)`, md: `calc(100vh - 74px)` },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        sx={{
          width: { xs: "initial", md: "calc(100vw - 400px)" },
          minHeight: "auto",
          "& .MuiButtonBase-root": {
            minHeight: "auto",
            py: { xs: 1.3, md: 0 },
            pb: { md: 1.3 },
            px: 1,
            textTransform: "none",
            color: (theme) => theme.palette.text.secondary,
            opacity: 1
          },
        }}
      >
        <Tab
          icon={<Campaign sx={{ fontSize: 16 }} />}
          iconPosition="start"
          label="Encuentros"
          {...a11yProps(0)}
        />
        <FactoresDropdown
          a11yProps={a11yProps}
          setValue={setValue}
          setType={setTypeFactor}
          setTypeC={setTypeCuota}
          type={typeFactor}
        />
        <CuotasDropdown
          a11yProps={a11yProps}
          setValue={setValue}
          setType={setTypeCuota}
          setTypeF={setTypeFactor}
          type={typeCuota}
        />
        <Tab
          icon={<EmojiEvents sx={{ fontSize: 16 }} />}
          iconPosition="start"
          label="Resultados"
          {...a11yProps(3)}
        />
      </Tabs>
      <Box>
        <CustomTabPanel value={value} index={0}>
          <Encuentros />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Factores type={typeFactor} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Cuotas type={typeCuota} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Resultados />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
